import {customElement, bindable, inject} from "aurelia-framework";
import {ArtificialIntelligence} from "../artificial-intelligence/helper/artificial-intelligence";
import {FlashService} from "../flash/flash-service";

@customElement('sio-textarea')
@inject(
    ArtificialIntelligence,
    FlashService
)
export class Textarea {
    @bindable field = null;
    @bindable({defaultBindingMode: 2}) value = null;

    constructor(
        artificialIntelligence,
        flash
    ) {
        this.artificialIntelligence = artificialIntelligence;
        this.flash = flash;
    }

    isArtificialIntelligenceEnabled() {
        // not the best solution, but removes the field from the prompt textarea
        return this.field.fullProperty !== 'prompt';
    }

    async openArtificialIntelligenceDialog(context) {
        context = context?.parent?.contextObjectRef;

        let ctx = {
            id: context.id,
            modelId: context.modelId,
        }

        let res = null;

        try {
            res = await this.artificialIntelligence.generate(ctx);

            if (!res.response) {
                this.flash.error('No answer from AI provider.');
            }

            this.field.value = res.response;
        } catch (error) {
            if (!res) {
                return;
            }

            console.error(error);
            this.flash.error(error);
        }
    }

    fieldChanged() {
        this.rows = this.field.rows ?? this.field.options?.rows ?? 5
    }
}
